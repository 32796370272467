// src/socket.js
import { io } from 'socket.io-client';

// バックエンドがホスティングされているIPまたはドメイン名とポートに変更
const socket = io('https://travel-class.com');

socket.on('connect', () => {
  console.log('Connected to the server');
});

socket.on('receiveMessage', (message) => {
  console.log('Received message:', message);
});

export default socket;
