import React, { useEffect } from 'react';
import './App.css';
import Home from './components/Home';
import PostDetails from './components/PostDetails';
import CreatePost from './components/CreatePost';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import icon from './assets/icon.png';
import socket from './socket';

function App() {
  useEffect(() => {
    // クリーンアップ: コンポーネントがアンマウントされるときにソケットを切断する
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-content">
            <Link to="/">
              <img src={icon} alt="Traveler's Class in Japan" className="header-icon" />
            </Link>
            <h1>Traveler's Class in Japan</h1>
          </div>
          <nav>
            <Link to="/">Home</Link>
            <Link to="/create-post">Create Post</Link>
          </nav>
        </header>
        <main>
          <section className="intro">
            <p>
            This platform allows travelers from abroad to freely share information with a wide range of people on various topics related to Japan, all in real-time.
            </p>
            <p>
            このプラットフォームは、海外からの旅行者が日本に関するさまざまなトピックについて、不特定多数の人とリアルタイムで自由に情報を共有できる場です。
            </p>
          </section>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/posts/:id" element={<PostDetails />} />
            <Route path="/create-post" element={<CreatePost />} />
          </Routes>
        </main>
        <Footer /> {/* フッターを全ページに表示 */}
      </div>
    </Router>
  );
}

export default App;
