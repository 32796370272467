import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import socket from '../socket';

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [search, setSearch] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState(''); // カテゴリの状態を追加
  const [prefectures, setPrefectures] = useState({});
  const [categories, setCategories] = useState({}); // カテゴリを保存
  const [chatCounts, setChatCounts] = useState({}); // 各ポストのチャット数を保存
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  useEffect(() => {
    fetchRandomPosts();

    // Socket.ioの接続を設定
    socket.on('receiveMessage', (message) => {
      console.log('Received message:', message);
      // 必要に応じてメッセージを処理
    });

    // クリーンアップ: コンポーネントがアンマウントされるときにイベントリスナーを解除
    return () => {
      socket.off('receiveMessage');
    };
  }, []);

  useEffect(() => {
    // 県リストの取得
    fetch('/prefectures.json')
      .then(response => response.json())
      .then(data => setPrefectures(data))
      .catch(error => console.error('Error fetching prefectures:', error));

    // カテゴリの取得
    fetch('/categories.json')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  // 各ポストのメッセージ数を取得
  const fetchChatCounts = async (postIds) => {
    const counts = {};
    for (const postId of postIds) {
      const response = await fetch(`https://travel-class.com/api/posts/${postId}/messageCount`);
      const data = await response.json();
      counts[postId] = data.messageCount;
    }
    setChatCounts(counts);
  };

  const fetchRandomPosts = async () => {
    const response = await fetch('https://travel-class.com/api/posts/random');
    const data = await response.json();
    setPosts(data);

    // ポストのチャット数を取得
    const postIds = data.map(post => post._id);
    fetchChatCounts(postIds);
  };

  const fetchPosts = async (params = {}) => {
    const query = new URLSearchParams(params).toString();
    const response = await fetch(`https://travel-class.com/api/posts?${query}`);
    const data = await response.json();
    setPosts(data);

    // ポストのチャット数を取得
    const postIds = data.map(post => post._id);
    fetchChatCounts(postIds);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value); // カテゴリの変更をハンドル
  };

  const handleSearchClick = () => {
    fetchPosts({ search, location, category }); // カテゴリを検索に含める
    setCurrentPage(1); // ページをリセット
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => (prevPage > 1 ? prevPage - 1 : 1));
  };

  return (
    <div className="home-container">
      <h2>Available Travelers</h2>
      <div className="filters">
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search posts..."
          className="search-field"
        />
        <select
          value={location}
          onChange={handleLocationChange}
          className="filter-field"
        >
          <option value="">Select Location</option>
          {Object.entries(prefectures).map(([name, value]) => (
            <option key={value} value={value}>
              {name} ({value})
            </option>
          ))}
        </select>
        <select
          value={category}
          onChange={handleCategoryChange}
          className="filter-field"
        >
          <option value="">Select Category</option>
          {Object.entries(categories).map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <button onClick={handleSearchClick} className="search-button">検索</button>
      </div>
      <div className="post-list">
        {currentPosts.map((post) => (
          <div key={post._id} className="post-card">
            <h3>{post.title}</h3>
            <p>{post.description}</p>
            <p><strong>Location:</strong> {post.location}</p>
            <p><strong>Category:</strong> {post.category}</p>
            <p><strong>Chat Count:</strong> {chatCounts[post._id] || 0}</p> {/* メッセージ数を表示 */}
            <Link to={`/posts/${post._id}`} className="details-link">View Details</Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={handlePrevPage} className="pagination-button">Prev</button>
        )}
        {indexOfLastPost < posts.length && (
          <button onClick={handleNextPage} className="pagination-button">Next</button>
        )}
      </div>
    </div>
  );
};

export default Home;
