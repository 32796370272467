import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="contact-info">
        <p>Contact us: <a href="mailto:travelclass0606@gmail.com">travelclass0606@gmail.com</a></p>
      </div>
      <div className="social-links">
        <p>Follow us: 
          <a href="https://x.com/travelclass0714?s=21&t=mGsxTc7ZE24um-797AjpyA" target="_blank" rel="noopener noreferrer"> Twitter</a> | 
          <a href="https://www.instagram.com/traveler_class?igsh=MTcxdjlmdG4xNTY4OA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer"> Instagram</a>
        </p>
      </div>
      <div className="copyright">
        <p>&copy; 2024 Travelclass All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
